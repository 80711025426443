<template>
  <NavBar :title="'优惠券信息'"></NavBar>
  <div class="content">
    <van-cell-group>
      <van-cell title="活动名称" :label="item.title" />
      <van-cell title="优惠券名称" :label="item.coupon[0].title" />
      <van-cell title="优惠券描述" :label="item.coupon[0].desc" />
      <van-cell title="优惠券金额" :label="item.coupon[0].money" />
      <van-cell title="满减金额" :label="item.coupon[0].full_money" />
      <van-cell title="开始日期" :label="item.start_date" />
      <van-cell title="结束日期" :label="item.end_date" />
      <van-cell title="每人限制核销数量" :label="item.user_num" />
      <van-cell title="总数量" :label="item.num" />
      <van-cell title="门店地址" :label="item.address" />
      <van-cell title="门店详细地址" :label="item.address" />
    </van-cell-group>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import { Cell, CellGroup } from 'vant'

export default {
  name: 'activityDetails',
  components: {
    NavBar,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
  },
  data() {
    return {
      item: this.$state.activity,
    }
  },
  mounted() {
    console.log(this.$state.activity)
  },
}
</script>
<style lang="less" scoped>
.content {
  // padding: 0 32px;
  position: relative;
  height: calc(100% - 55px);
  margin-bottom: 50px;
}
:deep(.van-cell:after) {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.26);
}
:deep(.van-cell__label) {
  font-size: 14px;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.85);
}
</style>
